import React, { useEffect, useState, useRef } from "react";
import "./moviewatch.css";
import ReactPlayer from "react-player";
import axios from "axios";
import { notification } from "antd"; // For error or success notifications
import { ClipLoader } from "react-spinners"; // Loading spinner

const MovieWatch = () => {
  const [movies, setMovies] = useState([]);
  const [currentMovieLink, setCurrentMovieLink] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedMovie, setSelectedMovie] = useState(null); // For better UI feedback
  const [userEmail, setUserEmail] = useState(null); // State for user email
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
  const [showWatermark, setShowWatermark] = useState(false); // State for watermark visibility
  const playerRef = useRef(null); // React Player reference
  const watermarkIntervalRef = useRef(null); // Ref to store the interval ID

  useEffect(() => {
    setLoading(true); // Start loading

    // Disable save shortcuts
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Retrieve user data from localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setUserEmail(user.email); // Store the user's email

      // Fetch movies the user has successfully paid for
      axios
        .post("https://showzsbackend.smartvaathi.com/api/check-movie-payment", {
          email: user.email,
        })
        .then((response) => {
          setLoading(false); // Stop loading
          if (response.data.status === "success") {
            setMovies(response.data.movies);
            if (response.data.movies.length > 0) {
              setCurrentMovieLink(response.data.movies[0].link); // Set first movie link as default
              setSelectedMovie(response.data.movies[0].title);
              fetchPlaybackProgress(response.data.movies[0].link);
            }
          } else {
            notification.error({
              message: "Error",
              description:
                "No paid movies found. Please complete a payment first.",
            });
          }
        })
        .catch((error) => {
          setLoading(false); // Stop loading on error
          console.error(
            "There was an error checking the movie payment status!",
            error
          );
        });
    } else {
      setLoading(false); // Stop loading
      console.error("No user data found in session storage.");
      notification.error({
        message: "Login Required",
        description: "Please log in to access your movies.",
      });
    }

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Automatically go fullscreen when a movie is loaded
    if (currentMovieLink && playerRef.current) {
      const videoElement = playerRef.current.getInternalPlayer();
      if (videoElement) {
        const requestFullscreen =
          videoElement.requestFullscreen ||
          videoElement.mozRequestFullScreen ||
          videoElement.webkitRequestFullscreen ||
          videoElement.msRequestFullscreen;
        if (requestFullscreen) {
          requestFullscreen.call(videoElement);
          setIsFullScreen(true); // Update the fullscreen state
        }
      }
    }
  }, [currentMovieLink]);

  const handleMovieSelect = (e) => {
    const selectedLink = e.target.value;
    const selectedTitle = e.target.options[e.target.selectedIndex].text;
    setCurrentMovieLink(selectedLink);
    setSelectedMovie(selectedTitle);
    fetchPlaybackProgress(selectedLink); // Fetch progress for the selected movie
  };

  const savePlaybackProgress = (movieLink, playbackPosition) => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      axios
        .post(
          "https://showzsbackend.smartvaathi.com/api/save-playback-progress",
          {
            email: user.email,
            movie_link: movieLink,
            playback_position: playbackPosition,
          }
        )
        .then((response) => {
          console.log("Progress saved successfully:", response.data);
        })
        .catch((error) => {
          console.error(
            "Failed to save playback progress:",
            error.response?.data || error.message
          );
        });
    }
  };

  const fetchPlaybackProgress = (movieLink) => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      axios
        .post(
          "https://showzsbackend.smartvaathi.com/api/get-playback-progress",
          {
            email: user.email,
            movie_link: movieLink,
          }
        )
        .then((response) => {
          if (
            response.data.status === "success" &&
            response.data.playback_position
          ) {
            setTimeout(() => {
              const videoElement = document.querySelector(
                ".react-player video"
              );
              if (videoElement) {
                videoElement.currentTime = response.data.playback_position;
              }
            }, 1000); // Allow time for video player to load
          }
        })
        .catch((error) => {
          console.error("Error fetching playback progress:", error);
        });
    }
  };

  const handleProgress = (progress) => {
    // Save progress only if playedSeconds changes
    savePlaybackProgress(currentMovieLink, progress.playedSeconds);
  };

  const handleFullScreen = (e) => {
    // Track if the player enters or exits full screen
    if (e.target.fullScreen) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  const handleStartMovie = () => {
    if (userEmail) {
      // Start the interval to toggle watermark visibility every 5 seconds
      watermarkIntervalRef.current = setInterval(() => {
        setShowWatermark((prev) => !prev); // Toggle watermark visibility
      }, 5000);
    }
  };

  const handleStopMovie = () => {
    // Stop the interval when the movie stops
    clearInterval(watermarkIntervalRef.current);
  };

  return (
    <section className="movie-watch">
      <h2>Watch Movie</h2>
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader color="#36d7b7" loading={loading} size={50} />
        </div>
      ) : movies.length > 0 ? (
        <>
          <div className="movie-select-container">
            <select onChange={handleMovieSelect} className="movie-select">
              {movies.map((movie, index) => (
                <option key={index} value={movie.link}>
                  {movie.title}
                </option>
              ))}
            </select>
          </div>
          <div
            className="movie-watch-container"
            style={{ position: "relative" }}
          >
            {currentMovieLink && (
              <>
                <div
                  className="player-wrapper"
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click
                >
                  <ReactPlayer
                    ref={playerRef} // Assigning ref to ReactPlayer
                    url={currentMovieLink}
                    controls
                    playing
                    className={`react-player ${
                      isFullScreen ? "fullscreen" : ""
                    }`}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload", // Disable download button
                          disablePictureInPicture: true, // Disable PiP
                        },
                      },
                    }}
                    onProgress={handleProgress} // Track playback progress
                    onFullScreen={handleFullScreen} // Track full-screen state
                    onStart={handleStartMovie} // Trigger watermark interval on movie start
                    onEnded={handleStopMovie} // Stop watermark interval when movie ends
                  />
                </div>
                {/* Watermark with user's email */}
                {showWatermark && userEmail && (
                  <div
                    className={`watermark-email ${
                      isFullScreen ? "full-screen" : ""
                    }`}
                  >
                    {userEmail}
                  </div>
                )}
                <div
                  className="player-overlay"
                  onContextMenu={(e) => e.preventDefault()}
                ></div>
              </>
            )}
            {selectedMovie && <p>Now Watching: {selectedMovie}</p>}
            <div className="expiration-notice">
              <strong>Notice:</strong> Enjoy your movie! 🎬 Your access will be
              available for the next 12 days. After that, it will expire, so
              make sure to watch it before time runs out! ⏳
            </div>
          </div>
        </>
      ) : (
        <p>
          No movies available to watch. Please make sure you have completed the
          payment.
        </p>
      )}
    </section>
  );
};

export default MovieWatch;
