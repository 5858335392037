import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import { ClipLoader } from "react-spinners"; // Import the spinner
import "./trailers.css";

const Trailers = () => {
  const [trailers, setTrailers] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchTrailers();

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchTrailers = async () => {
    try {
      const response = await axios.get(
        "https://showzsbackend.smartvaathi.com/api/news/latest"
      );
      console.log("API Response:", response.data);
      setTrailers(response.data.news);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching trailers:", error);
      setLoading(false); // Stop loading even on error
    }
  };

  return (
    <section className="now-showing">
      <div className="now-showing-container">
        {loading ? (
          <div className="loading-spinner">
            <ClipLoader size={50} color="#36D7B7" loading={loading} />
          </div>
        ) : trailers.length === 0 ? (
          <p>No trailers available at the moment.</p>
        ) : (
          trailers.slice(0, 2).map((news) => (
            <div
              className="movie-card animate__animated animate__fadeInLeft"
              key={news.id}
              onContextMenu={(e) => e.preventDefault()}
            >
              {news.trailer && (
                <ReactPlayer
                  url={news.trailer}
                  controls
                  className="react-player"
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload", // Disables download option
                        disablePictureInPicture: true, // Disables PiP
                      },
                    },
                  }}
                />
              )}
              <div className="movie-info">
                <h3>{news.title}</h3>
                <p>Description: {news.description}</p>
                {/* <p>Duration: {news.duration}</p>
                <p>Category: {news.category}</p>
                <p>Price: {news.price}</p> */}
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Trailers;
